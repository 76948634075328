@font-face {
  font-family: "VisbyRoundCFLight";
  src: local("VisbyRoundCFLight"),
    url(../fonts/VisbyRoundCF-Light.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFMedium";
  src: local("VisbyRoundCFMedium"),
    url(../fonts/VisbyRoundCF-Medium.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFDemiBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-DemiBold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-Bold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFExtraBold";
  src: local("VisbyRoundCFExtraBold"),
    url(../fonts/VisbyRoundCF-ExtraBold.otf) format("opentype");
}

@font-face {
  font-family: 'GorditaMedium';
  src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBlack';
  src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBold';
  src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaRegular';
  src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaLight';
  src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}

.thankyou_bg {
  background-image: url("../images/common_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;
}

.thankyou_blur_bg {
  background-image: url("../images/blur3person.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;
}

.box-1 {
  border: 1px solid #1585ff;
  border-radius: 15px;
  background-color: white;
  opacity: 0.7;
}

.bookpara {
  color: #fff;
  font-family: 'GorditaRegular';
  font-size: 16px;
}

.bookyourdoc {
  font-family: 'GorditaBold';
  color: #1585ff;
}

.christian_para {
  color: #1585ff;
  font-family: 'GorditaRegular';
  font-size: 20px;
}

.call_911 {
  color: #1585ff;
  font-family: 'GorditaRegular';
  font-size: 26px;
  padding-bottom: 50px !important;
}

.homebutton {
  font-family: "GorditaBold" !important;
}

.button-div {
  padding-bottom: 50px;
}

/*for book you doctor page*/

.box-2 {
  border: 1px solid #1585ff;
  border-radius: 15px;
  background-color: rgba(53, 135, 223, 0.7);
  padding: 20px;
}

.bookinput {
  background-color: rgb(141, 176, 213, 0.4) !important;
  border-color: white !important;
  height: 50px !important;
  color: #fff !important;
}

.bookinput::placeholder {
  color: white !important;
  font-family: "GorditaLight";
}

.ifyouthink {
  font-family: 'GorditaRegular';
  color: white;
}

.bookdoch3 {
  color: white;
  font-family: "GorditaMedium";
  font-size: 35px !important;
  padding-left: 10px;
  padding-top: 10px;
}

.thankyouclass {
  padding-top: 150px;
  padding-bottom: 255px;
}
.user-select label{
  color: #fff;
  margin-bottom: 10px;
}
.user-select select {
  height: 50px !important;
  background-color: rgb(141, 176, 213, 0.4) !important;
  color: white !important;
}

.user-date input {
  background-color: rgb(141, 176, 213, 0.4) !important;
  border: 1px solid #fff !important;
  color: white !important;
  height: 50px !important;
}

.user-date input:focus {
  box-shadow: none;
}

.user-date input::placeholder {
  color: #fff !important;
  font-family: "GorditaLight";
}

.user-select select option {
  background-color: rgb(141, 176, 213, 2) !important;
}

.user-select select:focus {
  box-shadow: none;
  border: 1px solid #fff;
}

.bookinput:focus {
  box-shadow: none !important;
}

.continue-btn {
  border: none;
  background-color: #1585ff;
  padding: 7px 20px;
  text-transform: uppercase;
  font-size: 14px;
  color: #fff;
  border-radius: 0.3em;
}

/**/

@media screen and (max-width: 750px) {
  .christian_para {
    font-size: 15px;
  }

  .call_911 {
    font-size: 20px;
    padding-bottom: 35px !important;
  }

  .button-div {
    padding-bottom: 35px;
  }

}

@media screen and (max-width: 450px) {
  .christian_para {
    font-size: 12px;
  }

  .homebutton {
    font-size: 12px !important;
  }

}

@media screen and (max-width: 380px) {
  .christian_para {
    font-size: 10px;
  }

  .homebutton {
    font-size: 12px !important;
  }

  /*book your doc*/
  .bookpara {
    font-size: 14px;
  }

  .bookinput {
    height: 35px !important;
  }

  .ifyouthink {
    font-size: 13px;
  }

  /* .centerclasss{
      padding-bottom: 450px;

  } */
}