@font-face {
  font-family: "VisbyRoundCFLight";
  src: local("VisbyRoundCFLight"),
    url(../fonts/VisbyRoundCF-Light.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFMedium";
  src: local("VisbyRoundCFMedium"),
    url(../fonts/VisbyRoundCF-Medium.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFDemiBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-DemiBold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-Bold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFExtraBold";
  src: local("VisbyRoundCFExtraBold"),
    url(../fonts/VisbyRoundCF-ExtraBold.otf) format("opentype");
}

@font-face {
  font-family: 'GorditaMedium';
  src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBlack';
  src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBold';
  src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaRegular';
  src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaLight';
  src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}

.pac-container {
  z-index: 10000 !important;
}

.profile_2bg {
  background-image: url("../images/profileBG2.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 100%; */
}

.second_part {
  padding-top: 150px;
}

.profile_pic {
  border: 1px solid;
  border-radius: 50%;
  border-color: #1585ff;
  width: 30%;
}

.profile_names {
  padding-left: 45px;
  padding-top: 45px;
}

.andre_name {
  color: #1585ff;
  font-size: 38px;
  font-family: 'GorditaMedium';
}

.book-table>td {
  font-size: 14px;
}

.hello {
  font-family: 'GorditaLight';
  color: #1585ff;
  font-size: 22px;
}

.logout-header {
  color: #1585ff;
}

.logout-header h1 {
  color: #1585ff;
  font-family: 'GorditaMedium';
  font-size: 18px;
  text-align: center;
}

.logout-close-btn {
  border: none;
  background-color: #fff;
  color: #1585ff !important;
  font-size: 14px;
  padding: 0;
}

.logout-close-btn:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
}

.logout-desc {
  margin: 0;
  color: #1585ff;
  font-family: 'GorditaMedium';
  font-size: 17px;
}

.logout-yes {
  border: none;
  color: #fff;
  background-color: #1585ff;
  border-radius: 0.3em;
  padding: 3px 20px;
}

.profile-first {
  display: flex;
  gap: 10px;
}

.logout-no {
  border: none;
  color: #1585ff;
  background-color: #fff;
  border-radius: 0.3em;
  padding: 3px 20px;
}

.horizontal_line {
  margin-top: 62px;
  border-top: 2px solid #1585ff;
}

.table-sections {
  padding-top: 50px;
}

.other_details {
  font-family: 'GorditaMedium';
  color: #1585ff;
  font-size: 30px;
}

.upcoming-btn {
  border-top-left-radius: 2em !important;
  border-bottom-left-radius: 2em !important;
  border-top-right-radius: 0rem !important;
  border-bottom-right-radius: 0rem !important;
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.past-btn {
  border-top-right-radius: 2em !important;
  border-bottom-right-radius: 2em !important;
  border-top-left-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.tablesection3 {
  margin-top: 45px !important;
  padding-bottom: 300px;
}

.lists {
  list-style-type: none;
  padding-left: 0px !important;
  font-size: 20px;
  font-family: 'GorditaLight';
  color: #1585ff;
}

.ul-lists {
  margin-bottom: 20px;
}

.ul-lists:hover {
  border-left: 1px solid #1585ff;
  padding-left: 8px;
  font-family: 'GorditaMedium';
  color: #1585ff;
  cursor: pointer;
}

.ul-lists-active {
  border-left: 1px solid #1585ff;
  padding-left: 8px;
  font-family: 'GorditaMedium';
  color: #1585ff;
  margin-bottom: 20px;
}

.tableclass>thead>tr>th {
  color: #1585ff;
  background-color: #9dcbfe;
}

.tableclass>tbody>tr>td {
  color: #1585ff;
  padding-top: 18px;
  padding-bottom: 18px;
}

thead {
  background-color: #9dcbfe !important;
}

tbody>.fortablelists {
  padding-top: 25px;
  padding-bottom: 25px;
}

.upcoming {
  text-align: end !important;
}

/* .upcoming-btn:hover{
background-color: #dff2ff !important;

} */

/*personal info*/
.personalinfos {
  color: #1585ff;
  font-size: 20px;
  font-family: 'GorditaMedium';
}

::placeholder {
  color: #1585ff !important;
  font-family: 'GorditaLight';
}

.forinputborder {
  border-color: #1585ff !important;
}

.status_buttons {

  font-size: 11px !important;
  padding: 2px 12px !important;
}

.forverticalline {
  border-right: 1px solid #1585ff;
}

.profile-input input:focus {
  box-shadow: none;
  outline: none;
}

.profile-input label {
  color: #1585ff;
}

.upload-btn {
  float: right;
  border: none;
  background-color: #1585ff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 0.3em;
}

.update-spinner {
  color: #1585ff !important;
  background-color: #fff !important;
  font-size: 12px;
}

.right-spinner {
  margin-top: 10px;
  color: #1585ff !important;
  background-color: #fff !important;
  font-size: 12px;
  float: right;
}

.right-spinner-wallet {
  margin-top: 10px;
  color: #1585ff !important;
  background-color: #fff !important;
  font-size: 12px;
  display: flex;
  justify-content: center;
}

.react-datepicker-wrapper {
  display: inline !important;
}

.react-datepicker__input-container .profile-date {
  border-color: #1585ff !important;
}

.gen-input select {
  border-color: #1585ff !important;
  color: #1585ff;
}
.gen-input label{
  color:#1585ff;
}

.gen-input select:focus {
  box-shadow: none;
}

.card-btn {
  border: none;
  padding: 5px 20px;
  background-color: #1585ff;
  color: #fff;
  border-radius: 0.3em;
  margin-top: 20px;
  float: right;
  font-family: 'GorditaBold';
}

.card-sec {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.card-sec p {
  color: #1585ff;
  font-size: 17px;
  font-family: 'GorditaMedium';
}

.card-err {
  color: red;
  font-family: 'GorditaLight';
}

.default-btn {
  border: none;
  border-radius: 0.3em;
  color: #fff;
  background-color: #1585ff;
  font-size: 13px;
  padding: 4px 10px;
}

.delete-card {
  color: rgb(252, 96, 96);
  cursor: pointer;
}

.no-data {
  color: #1585ff;
  font-size: 20px;
  font-family: 'GorditaMedium';
}

.err-data {
  color: red;
  font-size: 20px;
  font-family: 'GorditaMedium';
}

.doc-active {
  background-color: #1585ff !important;
  color: #fff !important;
}

.emp-serv {
  width: 100%;
  text-align: center;
}

.emp-serv p {
  color: #1585ff !important;
  font-family: 'GorditaBold';
  text-align: center;
}

.emp-serv button {
  border: none;
  color: #fff;
  background-color: #1585ff;
  border-radius: 0.3em;
  padding: 5px 10px;
}

.amount-btn {
  border: none;
  width: 100%;
  color: #1585ff;
  border: 1px solid #1585ff;
  padding: 7px 20px;
  background-color: #a9d1fd;
}

.add-money-btn {
  /* float: right; */
  margin-top: 20px;
  background-color: #1585ff;
  border: none;
  color: #fff;
  padding: 10px 20px;
  letter-spacing: 1px;
  border-radius: 3px;
}

.Wallet-line {
  color: #1585ff;
}

.amt-active {
  color: #fff !important;
  background-color: #1585ff !important;
}

@media screen and (max-width: 972px) {
  .profile_pic {
    height: 30%;
  }

  .profile_names {
    padding-left: 20px;
    padding-top: 15px;
  }

  .hello {
    font-size: 15px;
  }

  .andre_name {
    font-size: 18px;
  }

  /* .btns {
    top: 20px;
  } */
  .second_part {
    padding-top: 50px;
  }

  .horizontal_line {
    margin-top: 35px;
  }

  .forverticalline {
    border-right: 0px;
  }
}

@media (max-width: 754px) {

  .book-table>td {
    font-size: 10px;
  }

  .profile_names {
    padding-left: 15px;
    padding-top: 10px;
  }

  .hello {
    font-size: 12px;
  }

  .andre_name {
    font-size: 20px;
  }

  .other_details {
    font-size: 20px;
  }

  .upcoming-btn {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .past-btn {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }

  .btns>button {
    padding: 6px 6px 6px 6px !important;
    font-size: 10px;
  }

  .table-sections {
    padding-top: 45px;
  }
}

@media screen and (max-width: 555px) {
  .second_part {
    padding-left: 25px !important;
    padding-right: 25px !important;
  }

  .hello {
    font-size: 10px;
  }

  .tablesection2 {
    padding-top: 50px;
  }

  .status_buttons {

    font-size: 8px !important;
    padding: 2px 12px !important;
  }

  .forverticalline {
    border-bottom: 1px solid #1585ff;
  }
}

@media screen and (max-width: 487px) {

  .profile-first {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .upcoming-btn {
    font-size: 10px !important;
  }

  .past-btn {
    font-size: 10px !important;
  }

  .other_details {
    font-size: 15px;
    margin-bottom: 0px !important;
  }

  .tableclass>thead>tr>th {
    font-size: 12px;
  }

  .tableclass>tbody>tr>td {
    font-size: 12px;
  }
}

@media screen and (max-width: 376px) {


  .hello {
    font-size: 8px;
  }

  .tableclass>thead>tr>th {
    font-size: 10px;
  }

  .tableclass>tbody>tr>td {
    font-size: 10px;
  }
}