@font-face {
    font-family: 'VisbyRoundCFMedium';
    src: local('VisbyRoundCFMedium'), url(../fonts/VisbyRoundCF-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFDemiBold';
    src: local('VisbyRoundCFDemiBold'), url(../fonts/VisbyRoundCF-DemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFBold';
    src: local('VisbyRoundCFBold'), url(../fonts/VisbyRoundCF-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFExtraBold';
    src: local('VisbyRoundCFExtraBold'), url(../fonts/VisbyRoundCF-ExtraBold.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFExtraLight';
    src: local('VisbyRoundCFExtraLight'), url(../fonts/VisbyRoundCF-ExtraLight.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFLight';
    src: local('VisbyRoundCFLight'), url(../fonts/VisbyRoundCF-Light.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFRegular';
    src: local('VisbyRoundCFRegular'), url(../fonts/VisbyRoundCF-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaMedium';
    src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaBlack';
    src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaBold';
    src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaRegular';
    src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaLight';
    src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}


.group-img {
    width: 100%;
    height: 100%;
}

.ques-cont {
    color: #1585FF !important;
}

.ques-tag {
    margin-top: 20px;
    font-family: 'GorditaBold';
    font-size: 25px;
}

.ans-head {
    font-family: 'GorditaMedium';
    font-size: 23px;
    line-height: 1.3;
}

.pay-class {
    font-family: 'GorditaBold';
    color: #1585FF;
}

#pay-class {
    display: none;
}

.ans-desc {
    font-family: 'GorditaRegular';
    margin-top: 30px;
    color: black !important;
    font-size: 20px;
    margin-bottom: 50px !important;
}

.read-btn {
    margin: auto;
    display: block;
    text-align: center;
    border: none;
    color: white;
    font-family: 'GorditaBold';
    font-size: 18px;
    background-color: #1585FF;
    border-radius: 1.5em;
    padding: 10px 20px;

}

.service-head {
    text-align: center;
    color: #1585FF;
    font-size: 30px;
    font-family: 'GorditaBold';
}

.service-img {
    padding: 20px;
    background-color: #1585FF;
    text-align: center;
    border-radius: 4em;
}

.service-title {
    margin-top: 7px;
    font-family: 'GorditaMedium';
    font-size: 18px;
    color: black;
}

.note-desc {
    font-family: 'GorditaMedium';
    font-size: 17px;
    color: #1585FF;
}

.note-desc1 {
    font-family: 'GorditaBold';
    font-size: 20px;
    color: #1585FF;
}

.quick-con {
    color: #1585FF;
    font-size: 25px;
    font-family: 'GorditaBold';
}

.mail-con {
    color: black;
    font-size: 16px;
    font-family: 'GorditaRegular';
}

.mail-con span {
    color: #1585FF;
}

.footer-book-btn {
    margin-top: 30px;
    border: none;
    padding: 11px 20px 7px 20px !important;
    background-color: #fff;
    color: #1585FF;
    border-radius: 0.3em;
    letter-spacing: 1px;
    font-family: 'GorditaMedium';
}

.footer1-list {
    color: #fff;
    font-family: 'GorditaRegular';
    padding-left: 0.5em;
}

.footer1-list a {
    color: #fff;
    text-decoration: none;
}

.footer-help-btn {
    border: none;
    border-radius: 1.5em;
    padding: 11px 14px 7px 14px !important;
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 14px;
}

.footer1-list li {
    list-style-type: none;
    margin-bottom: 16px;
}

.footer-img-list li {
    list-style-type: none;
}

.footer-img-list {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.footer-img-list img {
    width: 30px;
    object-fit: contain;
}

.footer-sub {
    margin-top: 20px;
    color: #fff;
    font-family: 'GorditaMedium';
    text-transform: uppercase;
}

.footer {
    background-color: #1585FF;
    padding: 20px 0px;
}

.sub-head {
    color: #fff;
    font-family: 'GorditaMedium';
    font-size: 19px;
}

.footer-sub-email input {
    margin-top: 12px;
    border: none;
    background-color: #1585FF;
    border: 1px solid white;
    padding: 6px 6px;
    border-radius: 0.3em;
}

.show-service {
    margin-top: 0;
    display: flex;
    gap: 7px;
}

.footer-send-btn {
    margin-top: 12px;
    border: none;
    background-color: #fff;
    color: #1585FF;
    border-radius: 0.4em;
    font-family: 'GorditaMedium';
    font-size: 14px;
    padding: 11px 10px 7px 10px !important;
}

.footer-arrow {
    width: 30px !important;
    object-fit: contain;
}

.sub-email {
    display: flex;
    width: 90%;
}

.sub-email button {
    border: none;
    background-color: #1585FF;
    border-bottom: 2px solid white;

}

.sub-email input {
    width: -webkit-fill-available;
    border: none;
    background-color: #1585FF;
    border-bottom: 2px solid white;
}

.sub-email input:focus,
.footer-sub-email input:focus {
    outline: none;
}

.sub-email input::placeholder,
.footer-sub-email input::placeholder {
    color: #fff !important;
}

.footer-list {
    list-style-type: none;
    color: #fff;
    padding-left: 1em;
}

.footer-list a {
    text-decoration: none;
    color: #fff;
}

.footer-list-head {
    font-family: 'GorditaMedium' !important;
    font-size: 17px !important;
}

.footer-list li {
    font-family: 'GorditaRegular';
    font-size: 14px;
    margin-bottom: 7px;
}

.footer-img-class {
    display: flex;
    justify-content: space-between;
}

.social-icons {
    display: flex;
    justify-content: space-around;
}

.footer-logo {
    width: 50%;
    filter: brightness(0) invert(1);
}

.common-list {
    position: relative;
}

.non-severe-list {
    margin-top: 3px;
    display: flex;
    gap: 12px;
    background-color: #fff;
    padding: 10px;
    border: 1px solid #1585FF;
    border-radius: 0.3em;
    position: absolute;
    color: #1585FF;
    width: max-content;
    text-align: center;
    z-index: 2;
    transition: 1s;
}

.common-active {
    background-color: #1585FF;
    color: #fff !important;
    padding: 3px;
    border-radius: 0.2em;
}

.non-severe-list span {
    font-size: 9px;
}

.instagram-img {
    width: 20px;
    object-fit: contain;
}

.grid-container {
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 0px 60px;
}

.grid-item {
    text-align: center;
}

.access-img {
    width: 204px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0 7px 7px rgba(0, 0, 0, 0.25), 0 3px 4px rgba(0, 0, 0, 0.22);
}

.mobile-con {
    margin-top: 80px;
}

.arrow-img {
    margin-top: 50px;
}

.arrow-img img {
    width: 80% !important;
}

.double-img {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.access-title {
    margin-top: 10px;
    color: black;
    font-size: 20px;
    font-family: 'GorditaMedium' !important;
}

.access-desc {
    margin-top: 10px;
    color: black;
    font-size: 18px;
    font-family: 'GorditaRegular' !important;
    text-align: center;
}

.work-img {
    width: 100%;
    height: 100%;
}

.how-head {
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 24px;
}

.how-desc {
    font-size: 18px;
    font-family: 'GorditaRegular';
}

.service-content {
    margin-top: 40px;
    background-color: #1585FF;
    border-radius: 3em;
    padding: 20px;
    color: #fff;
}

.inner-cont {
    font-size: 21px;
    font-family: 'GorditaBold';
    margin-bottom: 0;
    text-transform: uppercase;
}


.choose-bg {
    background-image: url('../images/why_choose_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.serv-book-btn {
    background-color: #1585FF;
    color: #fff;
    padding: 10px 20px 7px 20px !important;
    border-radius: 0.75em;
    font-family: 'GorditaBold';
    border: none;
}

.choose-head {
    color: #1585FF;
    font-size: 25px;
    font-family: 'GorditaBold';
}

.serv-img1 {
    position: relative;
    background-image: url('../images/services-1.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.serv-icon1 {
    position: absolute;
    bottom: 0;
    right: 0;
    width: fit-content;
    object-fit: cover;
}

.serv-img2 {
    position: relative;
    background-image: url('../images/services-2.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.serv-icon2 {
    position: absolute;
    bottom: 0;
    width: fit-content;
    object-fit: cover;
}

.serv-img3 {
    position: relative;
    background-image: url('../images/services-3.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.serv-img5 {
    position: relative;
    background-image: url('../images/Pharma.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.serv-img6 {
    position: relative;
    background-image: url('../images/Lab-serv.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.serv-img7 {
    position: relative;
    background-image: url('../images/Imaging-serv.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}


.serv-img4 {
    position: relative;
    background-image: url('../images/services-4.png');
    background-repeat: no-repeat;
    background-position: center;
    height: 400px;
    border-radius: 952px;
    width: 426px !important;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.serv-read {
    display: flex;
    align-items: center;
}

.choose-read-btn {
    border: none;
    color: white;
    font-family: 'GorditaBold';
    font-size: 18px;
    background-color: #1585FF;
    border-radius: 1.5em;
    padding: 10px 20px;

}

.article-btn {
    margin-top: 20px !important;
    border: none;
    color: white;
    font-family: 'GorditaBold';
    font-size: 18px;
    background-color: #1585FF;
    border-radius: 1.5em;
    padding: 10px 20px;
    display: block;
    margin: auto;
    text-align: center;
    width: 60%;
}

.help-butn {
    display: flex;
    justify-content: end;
}

.help-btn {
    border: none;
    color: white;
    font-family: 'GorditaBold';
    font-size: 18px;
    background-color: #0D529D;
    border-radius: 2em;
    padding: 10px 40px;
}

.question {
    width: 20px;
    object-fit: contain;
}

.article-ques {
    font-family: 'GorditaBold';
}

.article-ans {
    font-family: 'GorditaMedium';
}

.article-post {
    font-family: 'GorditaLight';
}

.blog-head {
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 30px;
    text-align: center;
}

.blog-left-img {
    border-radius: 50px;
    width: 100%;
    object-fit: contain;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.blog-date {
    margin-top: 20px;
    margin-bottom: 0;
    font-size: 20px;
    font-family: 'GorditaBold';
}

.blog-title {
    margin-top: 10px;
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 20px;
}

.blog-sub-title {
    margin-top: 10px;
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 17px;
}

.blog-desc {
    color: black;
    font-family: 'GorditaMedium';
    font-size: 17px;
}

.blog-list {
    margin-top: 20px;
    display: flex;
    gap: 10px;
}

.recent-btn {
    font-family: 'GorditaBold';
    background-color: #fff;
    border: none;
    border-bottom: 4px solid #1585FF;
    font-size: 26px;
    color: #1585FF;
    padding: 0;
}

.blog-sm-img {
    border-radius: 2em;
    width: fit-content;
    height: 150px;
}

.blog-sm-date {
    margin-bottom: 0;
    font-size: 20px;
    font-family: 'GorditaBold';
}

.comment-btn {
    font-family: 'GorditaBold';
    background-color: #fff;
    border: none;
    border-bottom: 4px solid #1585FF;
    font-size: 26px;
    color: #1585FF;
    padding: 0;
}

.category-btn {
    margin-top: 15px;
    font-family: 'GorditaBold';
    background-color: #fff;
    border: none;
    border-bottom: 4px solid #1585FF;
    font-size: 26px;
    color: #1585FF;
    padding: 0;
}

.post-btn {
    margin-top: 15px;
    font-family: 'GorditaBold';
    background-color: #fff;
    border: none;
    border-bottom: 4px solid #1585FF;
    font-size: 26px;
    color: #1585FF;
    padding: 0;
}

.help-con-bg {
    background-image: url('../images/common_con_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
}

.help-sec-head {
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 30px;
    line-height: 1.3;
    text-align: center;
    padding-top: 20px;
}

.help-sec-desc {
    text-align: center;
    color: #1585FF;
    font-family: 'GorditaLight';
}

.help-text textarea {
    border-color: #1585FF !important;
}

.help-text textarea:focus {
    box-shadow: none;
}

.help-text textarea::placeholder,
.help-name input::placeholder {
    color: #1585FF;
    font-family: 'GorditaLight';
}

.textHelp {
    color: #1585FF !important;
}

.help-name input {
    border-color: #1585FF !important;
}

.help-name input:focus {
    box-shadow: none;
}

.help-select select:focus {
    box-shadow: none;
    border-color: #1585FF;
}

.help-select select {
    border-color: #1585FF;
    color: #1585FF !important;

}

.send-btn {
    width: 100%;
    border: none;
    background-color: #1585FF;
    padding: 6px 10px;
    color: #fff;
    border-radius: 0.3em;
}

.help-line {
    padding: 20px;
    color: #1585FF;
    margin: 20px;
}

.help-sec1-head {
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 20px;
    text-align: center;
    margin-bottom: 0;
}

.help-sec1-head1 {
    margin-top: 5px;
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 30px;
    line-height: 1.3;
    text-align: center;
}

.help-last-bg {
    background-image: url('../images/help_last.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 800px;
}

.questions-head a {
    padding: 0px 10px;
    text-decoration: none;
    color: #1585FF !important;
    font-family: 'GorditaRegular';
}

.questions-head li {
    margin-bottom: 10px;
}

.help-active a {
    border-left: 2px solid #1585FF;
    font-family: 'GorditaMedium';
    font-size: 20px;
}

.accordion-item,
.accordion-button {
    background-color: #e0effa !important;
}

.accordion-button {
    color: #1585FF !important;
    font-family: 'GorditaMedium';
}

.accordion-item {
    color: #1585FF !important;
    font-family: 'GorditaRegular';
}

.quest-help-img {
    width: 30px;
    margin-right: 14px;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-item {
    border-color: #1585FF !important;
}

.term-btn,
.privacy-btn {
    background-color: #fff;
    border: none;
    color: #1585FF;
    border: 1px solid #1585FF;
    font-family: 'GorditaBold';
    letter-spacing: 1px;
}

.term-btn-active {
    background-color: #1585FF;
    color: #fff;
}

.term-btn {
    border-top-left-radius: 2em;
    padding: 17px 30px;
    border-bottom-left-radius: 2em;
}

.privacy-btn {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    padding: 10px 70px;
}

.terms-content {
    margin-top: 20px;
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 16px;
}

.news-left-img {
    border-radius: 5px;
    width: 100%;
    object-fit: contain;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25), 0 1px 1px rgba(0, 0, 0, 0.22);
}

.news-title {
    color: #1585FF;
    margin-top: 20px;
    font-size: 16px;
    font-family: 'GorditaBold';
}

.news-desc {
    color: #1585FF;
    margin-top: 7px;
    font-size: 14px;
    font-family: 'GorditaMedium';
}

.like-img,
.comment-img {
    width: 20px;
}

.like-count {
    color: #1585FF;
    font-family: 'GorditaBold';
}

.news-right-con {
    display: flex;
    gap: 20px;
}

.news-right-img {
    width: 100%;
}

.news-subscribe {
    margin-top: 100px;
    padding: 40px 0px;
    background-color: #B2D6FE;
}

.news-sub {
    color: #1585FF;
    font-size: 20px;
    font-family: 'GorditaMedium';
}

.news-sub span {
    font-size: 30px;
    font-family: 'GorditaBold';
}

.news-subs-btn {
    border: none;
    background-color: #1585FF;
    color: #fff;
    border-radius: 0.4em;
    font-family: 'GorditaBlack';
    padding: 10px 20px;
}

.news-sub-email input {
    border: none;
    background-color: #e0eefd;
    border: 1px solid #1585FF;
    padding: 10px;
}

.news-sub-email input:focus {
    box-shadow: none;
    background-color: #e0eefd;
    border: 1px solid #1585FF;
}

.news-sub-email input::placeholder {
    color: #1585FF;
    font-family: 'GorditaMedium';
}

.lat-head {
    color: #1585FF;
    font-size: 30px;
    font-family: 'GorditaBold';
    text-align: center;
}

.lat-desc {
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 16px;
    text-align: center;
}

.event-btn {
    border: none;
    border-top-left-radius: 2em;
    padding: 10px 30px;
    border-bottom-left-radius: 2em;
    color: #1585FF;
    border: 1px solid #1585FF;
    font-family: 'GorditaBold';
    background-color: #fff;
}

.arti-btn {
    border: none;
    padding: 10px 30px;
    color: #1585FF;
    border: 1px solid #1585FF;
    font-family: 'GorditaBold';
    background-color: #fff;
}

.news-grp-btn {
    border: none;
    padding: 10px 30px;
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
    color: #1585FF;
    border: 1px solid #1585FF;
    font-family: 'GorditaBold';
    background-color: #fff;
}

.dura-text {
    color: #1585FF;
    font-family: 'GorditaBlack';
    margin: 0;
}

.more-btn {
    margin: auto;
    display: block;
    text-align: center;
    border: none;
    padding: 6px 30px;
    color: #fff;
    background-color: #1585FF;
    border-radius: 0.3em;
    font-family: 'GorditaBold';
}

.arti-text {
    text-align: center;
    color: #1585FF;
    font-size: 17px;
    font-family: 'GorditaMedium';
    text-transform: uppercase;
}

.view-news-title {
    text-align: center;
    color: #1585FF;
    font-size: 30px;
    font-family: 'GorditaBold';
}

.news-detail span {
    color: #1585FF;
    font-family: 'GorditaBlack';
}

.news-left-content {
    padding: 20px 10px;
    background-color: #1585FF;
    color: #fff;
    text-align: center;
    border-radius: 0.5em;
}

.news-left-title {
    font-family: 'GorditaBold';
    font-size: 23px;
}

.news-line {
    height: 7px;
    background-color: #1585FF;
    color: #1585FF;
}

.comments-detail {
    display: flex;
    gap: 20px;
}

.com-user-img {
    width: 40px;
    object-fit: contain;
}

.news-date {
    color: #1585FF;
    margin-top: 10px;
    font-size: 14px;
}

.comments-count {
    color: #1585FF;
    font-size: 16px;
    font-family: 'GorditaBlack';
}

.news-message textarea {
    border-color: #1585FF !important;
    background-color: #e1eefd;
}

.news-message textarea:focus {
    box-shadow: none;
}

.news-message textarea::placeholder,
.news-name input::placeholder {
    color: #1585FF;
    font-family: 'GorditaLight';
}

.news-name input {
    border-color: #1585FF !important;
    background-color: #e1eefd;
}

.news-name input:focus {
    box-shadow: none;
}

.news-name,
.news-message {
    color: #1585FF;
    font-family: 'GorditaBlack';
}

.comment-send-btn {
    border: none;
    padding: 10px 20px;
    background-color: #1585FF;
    color: #fff;
    font-size: 15px;
    font-family: 'GorditaBlack';

}

.book-content {
    border: 2px solid #1585FF;
    border-radius: 0.4em;
    padding: 30px;
    background-color: #fff;
}

.step-list a {
    text-decoration: none;
    font-size: 14px;
    padding: 8px 11px;
    border-radius: 50%;
    border: 1px solid #1585FF;

}

.step-left1 {
    position: relative;
    background-color: #fff;
    z-index: 2;
    left: 14px;
}

.step-left {
    background-color: #fff;
    z-index: 1;
    position: relative;
    left: 7px;
}

.step-active {
    position: relative;
    z-index: 3;
    background-color: #1585FF;
    color: #fff;
}

.right-head {
    margin-left: 10px;
    margin-top: 10px;
    color: #1585FF;
    font-family: 'GorditaMedium';
}

.step-head {
    color: #1585FF;
    font-size: 27px;
    letter-spacing: 1px;
    font-family: 'GorditaMedium';
    margin-bottom: 0;
}

.step-desc,
.start-head {
    color: #1585FF;
    font-family: 'GorditaRegular';
    margin-top: 5px;
}

.step-desc {
    font-size: 15px;
}

.start-head {
    font-size: 17px;
}

.left-content-steps {
    border-right: 1px solid #96c0ec;
}

.right-name-sec {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #96c0ec;
    padding-bottom: 7px;
}

.name-con {
    margin-bottom: 0;
    color: #1585FF;
    font-family: 'GorditaRegular';
}

.close-btn {
    font-size: 23px;
    margin-top: -4px;
    font-family: 'GorditaRegular';
    color: #1585FF;
    margin-right: 10px;
}

.name-edit {
    color: #1585FF;
    border: none;
    background: none;
    font-family: 'GorditaMedium';
    font-size: 14px;

}

.step-continue {
    border: none;
    background-color: #1585FF;
    color: #fff;
    font-family: 'GorditaRegular';
    border-radius: 0.3em;
    padding: 11px 15px 7px 15px !important;
    margin-top: 10px;
    float: right;
}

.sym-head {
    color: #1585FF;
    font-size: 25px;
    font-family: 'GorditaMedium';
    margin-bottom: 10px;
}

.sym-list {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.sym-list-item {
    padding: 7px;
    border-radius: 0.3em;
    color: #1585FF;
    border: 1px solid #1585FF;
    font-size: 16px;
    font-family: 'GorditaRegular';
}

.sym-list-item input {
    -webkit-appearance: none;
    border: 1px solid #1585FF;
    border-radius: 0.1em;
    padding: 5px;
    position: relative;
    float: right;
    margin-top: 5px;
}

.sym-list-item input:checked {
    background-color: #fff;
    border: 1px solid #1585FF;
    color: #1585FF;
}

.sym-list-item input:checked:after {
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: -2px;
    left: 0px;
    color: #1585FF;
}

.not-found {
    color: #1585FF;
}

.sym-status {
    margin-top: 4px;
    color: #1585FF;
    font-size: 14px;
    font-family: 'GorditaMedium';
}

.sym-status select {
    border: none;
    background: none;
    color: #1585FF;
    font-size: 14px;
    font-family: 'GorditaMedium';
}

.sym-status select:focus {
    outline: none;
}


.type-head {
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 17px;
    margin-bottom: 0;
    letter-spacing: .5px;
}

.book-types {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.book-types button {
    margin-top: 10px;
    border: none;
    border: 2px solid #1585FF;
    background-color: #fff;
    color: #1585FF;
    padding: 9px 10px 5px 10px !important;
    border-radius: 0.3em;
    font-family: 'GorditaMedium';
    font-size: 15px;
}

.type-active {
    background-color: #1585FF !important;
    color: #fff !important;
}

.clinic-table>thead>tr,
.clinic-table>tbody>tr {
    border-bottom: 1px solid #96c0ec;
}

.clinic-table>thead>tr>th {
    color: #1585FF !important;
    font-family: 'GorditaMedium';
    padding: 10px 0px;
    font-size: 18px;
}

.clinic-table>tbody>tr>td {
    color: #1585FF !important;
    font-family: 'GorditaRegular';
    padding: 20px 0px;
    font-size: 17px;
}

.clinic-table>tbody>tr>td>button {
    border: none;
    border: 2px solid #1585FF;
    background-color: #fff;
    color: #1585FF;
    text-transform: uppercase;
    border-radius: 0.4em;
    font-size: 12px;
    margin-top: -6px;
    padding: 6px 5px 2px 5px !important;

}

.right-sym-sec {
    display: flex;
    justify-content: space-between;
    padding-bottom: 7px;
}

.total-text {
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 17px;
}

.total-amount {
    color: #1585FF;
    font-size: 25px;
    font-family: 'GorditaMedium';
}

.schedule-class {
    color: #1585FF;
    font-family: 'GorditaRegular';
    font-size: 14px;
}

.cash-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 25px;
    justify-content: flex-start;
    padding-bottom: 16px;
    border-bottom: 1px solid #afd1f5;
    flex-wrap: wrap;
}

.cash-list>li>a {
    text-decoration: none;
    color: #afd1f5;
    font-family: 'GorditaMedium';
    letter-spacing: 1px;
    font-size: 17px;
}

.pay-active {
    color: #1585FF !important;
    border: 2px solid #1585FF;
    padding: 7px;
}


.pay-head {
    color: #1585FF;
    font-family: 'GorditaBold';
    margin-bottom: 0;
    letter-spacing: 1px;
}

.card-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    gap: 25px;
    justify-content: flex-start;
    flex-wrap: wrap;
}

/* wallet */
.card-list-wallet {
    list-style-type: none;
    padding: 0;
    gap: 25px;
}


.card-class {
    padding-bottom: 0px;
    border-bottom: 1px solid #afd1f5;
}

.add_card {
    border: none;
    background-color: #1585FF;
    border-radius: 0.3em;
    color: #fff;
    font-size: 14px;
    font-family: 'GorditaMedium';
    padding: 7px 10px;
    float: right;
}

.card-list>li>a {
    text-decoration: none;
    color: #afd1f5;
    font-family: 'GorditaMedium';
    font-size: 17px;
}

.card-list-wallet>li>a {
    text-decoration: none;
    color: #afd1f5;
    font-family: 'GorditaMedium';
    font-size: 17px;
}

.new-card select {
    border: none;
    background: none;
    color: #1585FF;
    font-size: 14px;
    font-family: 'GorditaMedium';
}

.new-card select:focus {
    outline: none;
}

.card-active {
    color: #1585FF !important;
    border: 2px solid #1585FF;
    padding: 7px;
}

.terms-class {
    border-radius: 0.3em;
    color: #1585FF;
    font-size: 16px;
    font-family: 'GorditaMedium';
}

.terms-class input {
    -webkit-appearance: none;
    border: 1px solid #1585FF;
    border-radius: 0.1em;
    padding: 5px;
    display: inline-block;
    position: relative;
}

.terms-class input:checked {
    background-color: #fff;
    border: 1px solid #1585FF;
    color: #1585FF;
}

.terms-class input:checked:after {
    content: '\2714';
    font-size: 12px;
    position: absolute;
    top: -5px;
    left: 0px;
    color: #1585FF;
}

.promo-class {
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 16px;
}

.book-log-head {
    color: #1585FF;
    font-family: 'GorditaBold';
    margin-bottom: 0;
    letter-spacing: 1px;
    font-size: 18px;
}

.book-fb-btn {
    color: #fff;
    background-color: #1585FF;
    border: none;
    width: 100%;
    border-radius: 0.25em;
    font-family: 'GorditaMedium';
    font-size: 20px;
    padding: 10px 10px 6px 10px !important;
}

.book-line-class {
    position: relative;
}

.book-line-class span {
    text-align: center;
    color: #1585FF;
    position: absolute;
    top: -7px;
}

.right-hr {
    margin-left: 15px !important;
}

.left-hr {
    margin-right: 15px !important;
}

hr {
    opacity: 0.8 !important;
}

.book-line-class hr {
    margin-top: 0 !important;
    color: #1585FF !important;
    border: 2px solid #007bff !important;
}

.book-email-input input {
    border-radius: 0.25em;
    border: none;
    border: 1px solid #1585FF;
    padding: 8px;
    width: 100%;
}

.book-pass-input input {
    border-radius: 0.25em;
    border: none;
    border: 1px solid #1585FF;
    padding: 8px;
    width: 100%;
}

.book-email-input input::placeholder,
.book-pass-input input::placeholder {
    font-family: 'GorditaLight';
    color: #1585FF;
}

.book-email-input input:focus,
.book-pass-input input:focus {
    border: 1px solid #1585FF;
    outline: none;
}

.book-log-last {
    display: flex;
    justify-content: space-between;
}

.book-log-btn {
    color: #fff;
    background-color: #1585FF;
    border: none;
    border-radius: 0.25em;
    font-size: 17px;
    font-family: 'GorditaMedium';
    padding: 6px 30px 2px 30px !important;
}

.book-log-last span {
    font-family: 'GorditaMedium';
    color: #1585FF;
    font-size: 15px;
}

.book-login-end {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'GorditaBold';
    font-size: 15px;
}

.book-login-end p {
    color: #1585FF;
    margin: 0;
}

.book-login-end a {
    color: #1585FF;
    font-weight: 700;
    line-height: 1;
    padding: 10px;
}

.book-sign-last {
    display: flex;
    gap: 10px;
    justify-content: space-between;
}

.book-sign-last span {
    font-family: 'GorditaMedium';
    color: #1585FF;
    font-size: 15px;
    letter-spacing: .7px;
}

.book-sign-last a {
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 17px;
}

.location-select select:focus {
    box-shadow: none;
    outline: none;
}

.location-select select {
    background-color: #dfedfc;
    color: #1585FF !important;
    border: none;
    border-radius: 0.3em;
    padding: 6px 10px;
    font-family: 'GorditaBlack';
    font-size: 14px;
    border: 2px solid #1585FF;
}

.location-select select>option {
    background-color: #AFD4FE;
    border-color: #fff !important;
}

.location-select label {
    color: #1585FF;
    text-transform: uppercase;
    font-size: 14px;
    font-family: 'GorditaBlack';
    letter-spacing: 1px;
}

.choose-clinic {
    color: #1585FF;
    font-family: 'GorditaMedium';
    margin: 0;
    padding: 0;
    font-size: 16px;

}

.info-head {
    display: flex;
    gap: 10px;
    color: #1585FF;
}

.clinic-name {
    font-family: 'GorditaBold';
    font-size: 16px;
}

.clic-addr {
    color: #1585FF;
    font-family: 'GorditaMedium';
    font-size: 15px;
    margin-bottom: 0;
}

.addr-btn {
    border: none;
    background-color: #1585FF;
    color: #fff;
    padding: 5px 5px;
    font-size: 13px;
    float: right;
}

.search-box {
    margin-bottom: 140px;
    position: relative;
}

.search::placeholder {
    font-size: 14px;
    font-family: 'GorditaMedium';
}

.search {
    font-size: 18px;
    padding: 10px;
    height: 40px;
    width: 450px;
    border: 1px solid #1585FF;
    outline: none;
    border-radius: 5px;
    color: #1585FF;
    background-color: #e2eefc;
}

datalist {
    position: absolute;
    background-color: #e2eefc;
    border: 1px solid #1585FF;
    /* border-radius: 0 0 0.5em 0.5em; */
    border-top: none;

    font-family: sans-serif;
    border-bottom-left-radius: 0.2em;
    border-bottom-right-radius: 0.2em;
    width: 450px;
    padding: 5px;
    height: 7rem;
    overflow-y: auto;
}

/* datalist>option {
    padding: 4px;
    color: #1585FF !important;
    margin-bottom: 1px;
    font-size: 16px;
    font-family: 'VisbyRoundCFDemiBold';
    cursor: pointer;
    scroll-behavior: smooth;
    position: relative;
} */

::-webkit-scrollbar {
    width: 3px;
}

::-webkit-scrollbar-track {
    background-color: #e2eefc;
}

::-webkit-scrollbar-thumb {
    height: 40px;
    background: #51a0f5;
}

::-webkit-scrollbar-track-piece:start {
    margin-top: 10px;
}

::-webkit-scrollbar-track-piece:end {
    /* margin-bottom: 10px; */
}

.severe-class {
    color: #1585FF;
    display: flex;
    justify-content: space-between;
    height: 2em;
}

.option-value {
    font-size: 15px;
}

.severe-class span {
    font-family: 'GorditaMedium';
    font-size: 10px;
}

.severe-class a {
    font-family: 'GorditaMedium';
    font-size: 10px;
}

.severe-class button {
    border: none;
    background-color: #1585FF;
    font-size: 10px;
    font-family: 'GorditaMedium';
    padding: 4px 8px;
    color: #fff;
    border-radius: 0.3em;
}

.severe-first {
    text-align: end;
}

.severe-list {
    margin-top: 3px;
    display: flex;
    gap: 14px;
    background-color: #fff;
    padding: 10px;
    text-align: center;
    border: 1px solid #1585FF;
    border-radius: 0.3em;
    position: absolute;
    right: 5px;
}

.severe-list span {
    font-size: 9px !important;
}

.time-select,
.special-select {
    color: #1585FF;
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'GorditaMedium';
}

.time-select input {
    border: none !important;
    border: 2px solid #1585FF !important;
    padding: 11px 7px 7px 7px !important;
    color: #1585FF;
    font-family: 'GorditaRegular';
    border-radius: 0.3em;
}

.time-select input:focus {
    outline: none;
    border: 2px solid #1585FF !important;
    box-shadow: none;
    color: #1585FF !important;
}

.special-select select {
    border: none !important;
    border: 2px solid #1585FF !important;
    padding: 7px;
    color: #1585FF;
    font-family: 'GorditaMedium';
    border-radius: 0.3em;
}

.special-select select:focus {
    box-shadow: none;
}

.book-spinner {
    color: #1585FF;
    background-color: #fff;
    border: none;
    font-size: 12px;
}

.book-in {
    color: white;
    font-family: 'GorditaMedium';
}

.dot-spinner {
    float: right;
}

.pay-con {
    color: #1585FF;
}

@media (max-width: 1300px) {
    .article-btn {
        width: 70%;
    }

    .footer-list-head {
        font-size: 15px !important;
    }

    .footer-list li {
        font-size: 12px;
    }
}

@media (max-width: 1200px) {
    .book-fb-btn {
        font-size: 12px;
    }

    .schedule-class {
        font-size: 13px;
    }

    .serv-img1 {
        width: 345px !important;
        height: 355px;
    }

    .serv-img2 {
        width: 345px !important;
        height: 355px;
    }

    .serv-img3,
    .serv-img5,
    .serv-img6,
    .serv-img7 {
        width: 345px !important;
        height: 355px;
    }

    .serv-img4 {
        width: 345px !important;
        height: 355px;
    }

    .work-img img {
        width: 100% !important;
    }

    .ques-tag {
        margin-top: 10px;
    }

    .ans-desc {
        margin-top: 10px;
        margin-bottom: 0;
    }
}

@media (max-width: 1000px) {

    .search,
    datalist {
        width: 330px;
    }

    .clinic-table>thead>tr>th {
        font-size: 14px;
    }

    .clinic-table>tbody>tr>td {
        font-size: 13px;
    }

    .clinic-table>tbody>tr>td>button {
        font-size: 12px;
    }

    .access-img {
        width: 145px;
        height: 145px;
    }

    .blog-title {
        font-size: 18px;
    }

    .article-btn {
        width: 100%;
    }

    .serv-img1 {
        width: 310px !important;
        height: 305px;
    }

    .serv-img2 {
        width: 310px !important;
        height: 305px;
    }

    .serv-img3,
    .serv-img5,
    .serv-img6,
    .serv-img7 {
        width: 310px !important;
        height: 305px;
    }

    .serv-img4 {
        width: 310px !important;
        height: 305px;
    }
}

@media (max-width: 767px) {
    .cash-list>li>a {
        font-size: 12px;
    }

    .option-value {
        font-size: 12px;
    }

    .book-sign-last span {
        font-size: 12px;
    }

    .book-sign-last a {
        font-size: 14px;
    }

    .book-log-btn {
        padding: 2px 10px;
    }

    .clinic-table>thead>tr>th {
        font-size: 12px;
    }

    .clinic-table>tbody>tr>td {
        padding: 10px 10px;
        font-size: 11px;
    }

    .clinic-table>tbody>tr>td>button {
        font-size: 10px;
    }

    .event-btn,
    .arti-btn,
    .news-grp-btn {
        padding: 10px 10px;
    }

    .term-btn {
        font-size: 14px;
        padding: 17px 20px;
    }

    .privacy-btn {
        font-size: 14px;
        padding: 10px 50px;
    }

    .mobile-img {
        width: 100%;
    }

    .help-name {
        margin-top: 20px;
    }

    .help-select {
        margin-top: 20px;
    }

    .send-btn {
        margin-top: 20px;
    }

    .break-no {
        display: none;
    }

    .blog-title {
        font-size: 16px;
    }

    .recent-btn {
        margin-top: 20px;
    }

    .logo-img {}

    .choose-img {
        margin-top: 20px !important;
    }

    .serv-icon1,
    .serv-icon2 {
        width: 70px;
    }

    .serv-img1 {
        width: 285px !important;
        height: 278px;
    }

    .serv-img2 {
        width: 285px !important;
        height: 278px;
    }

    .serv-img3,
    .serv-img5,
    .serv-img6,
    .serv-img7 {
        width: 285px !important;
        height: 278px;
    }

    .serv-img4 {
        width: 285px !important;
        height: 278px;
    }

    .ser1-sec {
        margin-top: 20px !important;
    }

    .ser2-sec {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center !important;
    }

    .serv-img-desc {
        margin-top: 10px !important;
    }

    .choose-bg {
        background-position: left;
    }

    .work-img img {
        width: 80% !important;
    }

    .grid-container {
        grid-template-columns: auto;
    }

    .grid-item {
        margin-top: 10px !important;
    }

    .arrow-img {
        display: none;
    }

    .severe-list span,
    .non-severe-list span {
        font-size: 4px;
    }

    .sym-list {
        flex-direction: column;
    }
}

@media (max-width: 400px) {

    .severe-class a {
        font-size: 8px;
    }

    .severe-class span {
        font-size: 8px;
    }

    .option-value {
        font-size: 12px;
    }

    .book-sign-last {
        flex-direction: column;
        gap: 10px;
    }

    .search,
    datalist {
        width: 300px;
    }



    .severe-list {
        gap: 10px;
    }
}

@media (max-width:380px) {

    .search,
    datalist {
        width: 270px;
    }

}