@font-face {
  font-family: "VisbyRoundCFLight";
  src: local("VisbyRoundCFLight"),
    url(../fonts/VisbyRoundCF-Light.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFMedium";
  src: local("VisbyRoundCFMedium"),
    url(../fonts/VisbyRoundCF-Medium.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFDemiBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-DemiBold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-Bold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFExtraBold";
  src: local("VisbyRoundCFExtraBold"),
    url(../fonts/VisbyRoundCF-ExtraBold.otf) format("opentype");
}

@font-face {
  font-family: 'GorditaMedium';
  src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBlack';
  src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBold';
  src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaRegular';
  src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaLight';
  src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}

.password_reset_bg {
  background-image: url("../images/common_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
}

.password-right {
  background-color: #1585ff;
}

.forbackborder {
  border: 1px solid #1585ff;
}

.text_pswd_reset {
  font-size: 48px;
  color: white;
  font-family: 'GorditaBold';
}

.passworddescrib {
  color: white;
  padding-top: 5px;
  font-family: 'GorditaLight';
}

.form_of_forgotpassword {
  padding-top: 80px;
}

.dothaveaccount {
  margin-top: 10px;
  color: white;
  font-family: 'GorditaMedium';
}

.forgotsendbtn {
  color: #1585ff !important;
  border: 1px solid white !important;
  background-color: white !important;
  font-family: 'GorditaBold' !important;
  padding: 6px 30px 2px 30px !important;
  font-size: 17px !important;
}

.rowpaddings {
  padding: 3rem 3rem 0rem 3rem !important;
}

.send-spinner {
  color: #ffff;
  background-color: #1585ff;
  border: none;
  font-size: 12px;
}

@media screen and (max-width: 380px) {
  .rowpaddings {
    padding: 1rem 1rem 1rem 1rem !important;
  }

  .text_pswd_reset {
    font-size: 24px;
  }

  .form_of_forgotpassword {
    padding-top: 10px;
  }

  .dothaveaccount {
    font-size: 12px;
  }

  .forgotsendbtn {

    font-size: 15px !important;
  }
}

@media screen and (max-width: 550px) {
  .rowpaddings {
    padding: 1rem 1rem 1rem 1rem !important;
  }

  .text_pswd_reset {
    font-size: 28px;
  }

  .form_of_forgotpassword {
    padding-top: 10px;
  }
}