@font-face {
  font-family: "VisbyRoundCFLight";
  src: local("VisbyRoundCFLight"),
    url(../fonts/VisbyRoundCF-Light.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFMedium";
  src: local("VisbyRoundCFMedium"),
    url(../fonts/VisbyRoundCF-Medium.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFDemiBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-DemiBold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFBold";
  src: local("VisbyRoundCFDemiBold"),
    url(../fonts/VisbyRoundCF-Bold.otf) format("opentype");
}

@font-face {
  font-family: "VisbyRoundCFExtraBold";
  src: local("VisbyRoundCFExtraBold"),
    url(../fonts/VisbyRoundCF-ExtraBold.otf) format("opentype");
}

@font-face {
  font-family: 'GorditaMedium';
  src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBlack';
  src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaBold';
  src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaRegular';
  src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
  font-family: 'GorditaLight';
  src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}

.thankyoufinal_bg {
  background-image: url("../images/common_BG.png");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 100vh;
  background-position: center;
  display: flex;
  align-items: center;
}

.box-3 {
  border: 1px solid #1585ff;
  border-radius: 15px;
  background-color: white;
  /* opacity: 0.7; */
  padding: 15px 15px 15px 15px;
}

.box-4 {
  background-color: #3785ff;
  border-radius: 15px;
}

.than {
  margin-bottom: 0 !important;
  padding-bottom: 15px;
  font-family: 'GorditaBold';
  color: white;
}

.booking_ids {
  color: white;
  padding-top: 10px;
  font-family: 'GorditaBold';
}

.thankyoupara {
  color: white;
  font-size: 13px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.part2 {
  font-family: 'GorditaLight';
}

.google-cal {
  font-size: 12px;
  border-color: white;
  background-color: #3785ff !important;
}

.this_is_your_first {
  background-color: #3c7eff;
  padding: 5px;
}

.firstserviceh3 {
  color: white;
  font-size: 17px !important;
  font-family: 'GorditaBold';
  margin-bottom: 0px !important;
}

.firstservicepara {
  font-size: 12px;
  color: white;
  padding-top: 5px;
}

.contact-us-through {
  color: white;
  font-size: 15px;
  margin-bottom: 0px !important;
  font-family: 'GorditaBold';
}

.thankyou_profile {
  border: 1px solid #1585ff;
  border-radius: 50px;
  padding-left: 5px;
}

.book-data {
  display: flex;
  gap: 10px;
  justify-content: end;
}

.user-pic {
  background-color: #fff;
  padding: 4px;
}

.meetEnd {
  background-color: #1585ff;
  min-height: 100vh;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.meet-logo {
  width: 100%;
  filter: brightness(0) invert(1);
  margin-bottom: 20px;
}

.go-btn {
  border: none;
  border-radius: 0.3em;
  background-color: #fff;
  color: #1585ff;
  padding: 7px 10px;
}

@media screen and (max-width: 850px) {
  .this_is_row2 {
    padding-left: 13px;
    padding-right: 13px;
  }

  .this_is_row3 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }
}

@media screen and (max-width: 390px) {
  .this_is_row2 {
    padding-left: 13px;
    padding-right: 13px;
  }

  .this_is_row3 {
    padding-left: 13px !important;
    padding-right: 13px !important;
  }

  .this_is_your_firstcol-6 {

    padding-top: 15px;
  }

  .google-cal {
    font-size: 12px !important;
  }

}

@media screen and (max-width:560px) {
  .this_is_your_firstcol-6 {

    padding-top: 25px;
  }
}