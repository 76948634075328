@font-face {
    font-family: 'VisbyRoundCFLight';
    src: local('VisbyRoundCFLight'), url(../fonts/VisbyRoundCF-Light.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFMedium';
    src: local('VisbyRoundCFMedium'), url(../fonts/VisbyRoundCF-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFDemiBold';
    src: local('VisbyRoundCFDemiBold'), url(../fonts/VisbyRoundCF-DemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFBold';
    src: local('VisbyRoundCFDemiBold'), url(../fonts/VisbyRoundCF-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFExtraBold';
    src: local('VisbyRoundCFExtraBold'), url(../fonts/VisbyRoundCF-ExtraBold.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaMedium';
    src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaBlack';
    src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaBold';
    src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaRegular';
    src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaLight';
    src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}




.common-bg {
    background-image: url("../images/common_BG.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.login-left img {
    width: 100% !important;
    height: 100%;
}

.login-right {
    background-color: #1585FF;
    position: relative;
}

.login-content {
    font-family: 'GorditaLight';
    color: #fff;
}

.login-form {
    margin-top: 150px !important;
}

.login-loading {
    position: absolute;
    height: 100Vh;
    display: flex;
    justify-content: center !important;
    align-items: center;
    place-items: center;
    text-align: center;
    width: 100%;
    z-index: 10;
    /* color: #a39191; */
}

.dots {
    display: none;
}

.sub-load {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

}

.fb-btn {
    color: #1585FF;
    background-color: #fff;
    border: none;
    width: 100%;
    border-radius: 0.25em;
    font-family: 'GorditaMedium';
    font-size: 20px;
    padding: 8px 10px 5px 10px !important;
}

.line-class {
    position: relative;
}

.line-class span {
    text-align: center;
    color: #fff;
    position: absolute;
    top: -7px;
}

.right-hr {
    margin-left: 15px !important;
}

.left-hr {
    margin-right: 15px !important;
}

.line-class hr {
    margin-top: 0 !important;
    color: #fff !important;
    border: 2px solid white !important;
}

.email-input input {
    border-radius: 0.25em;
    border: none;
    padding: 8px;
    width: 100%;
}

.pass-input input {
    border-radius: 0.25em;
    border: none;
    padding: 8px;
    width: 100%;
}

.email-input input::placeholder {
    font-family: 'GorditaRegular';
    color: #1585FF;
}

.pass-input input::placeholder {
    font-family: 'GorditaRegular';
    color: #1585FF;
}

.email-input input:focus {
    outline: none;
}

.pass-input input:focus {
    outline: none;
}

.log-last {
    display: flex;
    justify-content: space-between;
}

.login-head {
    margin-top: 30px;
    letter-spacing: 1px;
    font-size: 30px;
    font-family: 'GorditaBold';
    color: #fff;
}

.log-spinner {
    color: #ffff;
    background-color: #1585ff;
    border: none;
    font-size: 12px;
}

.log-btn {
    color: #1585FF;
    background-color: #fff;
    border: none;
    border-radius: 0.25em;
    font-size: 20px;
    font-family: 'GorditaMedium';
    padding: 6px 30px 1px 30px !important;
}

.log-last a {
    margin-top: 10px;
    font-family: 'GorditaMedium';
    color: #fff;
    text-decoration: none;
}

.sign-last {
    display: flex;
    justify-content: end;
}

.login-end {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-family: 'GorditaBold';
}

.login-end p {
    color: #fff;
    margin: 0;
}

.login-end a {
    color: #fff;
    font-weight: 700;
    line-height: 1;
    padding: 10px;
}

.login-end a:hover {
    color: #fff;
}

.login-end {
    position: absolute;
    bottom: 20px;
}

.bottom-line {
    margin: 20px !important;
    border: 1px solid white;
}

.err-txt {
    font-family: 'GorditaRegular';
    color: red;
    position: relative;
    top: 5px;
    font-size: 15px;
}

.verify-class {
    display: flex;
    justify-content: space-between;
    gap: 15px
}

@media (max-width: 1200px) {
    .fb-btn {
        font-size: 13px;
    }

    .login-form {
        margin-top: 50px !important;
    }
}

@media (max-width: 1000px) {


    .login-end {
        position: static;
        margin-top: 10px;
    }

    .login-form {
        margin-top: 30px !important;
    }
}

@media (max-width: 767px) {
    .fb-btn {
        font-size: 11px;
    }
    .login-end {
        margin-top: 20px !important;
        position: relative;
    }
}