@font-face {
    font-family: 'VisbyRoundCFMedium';
    src: local('VisbyRoundCFMedium'), url(../fonts/VisbyRoundCF-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'VisbyRoundCFBold';
    src: local('VisbyRoundCFBold'), url(../fonts/VisbyRoundCF-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaMedium';
    src: local('GorditaMedium'), url(../Gordita/GorditaMedium.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaBlack';
    src: local('GorditaBlack'), url(../Gordita/GorditaBlack.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaBold';
    src: local('GorditaBold'), url(../Gordita/GorditaBold.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaRegular';
    src: local('GorditaRegular'), url(../Gordita/GorditaRegular.otf) format('opentype');
}

@font-face {
    font-family: 'GorditaLight';
    src: local('GorditaLight'), url(../Gordita/GorditaLight.otf) format('opentype');
}

::selection{
    color: #fff;
    background-color: #1585FF;
}

.home-img {
    position: relative;
    background-image: url('../images/home_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.service-bg {
    position: relative;
    background-image: url('../images/banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    height: 100vh;
}

.article-bg {
    position: relative;
    background-image: url('../images/banner_article.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
    height: 100vh;
}

.blog_bg {
    position: relative;
    background-image: url('../images/blog_banner_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 80vh;
}

.blog_img {
    position: absolute;
    bottom: 0;
    background-image: url('../images/blog_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 60vh;
    width: 100%;
}

.help-bg {
    position: relative;
    background-image: url('../images/help_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 90vh;
}

.profile_bg {
    position: relative;
    background-image: url('../images/profile_bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 90vh;
}

.nav-class {
    background-color: #fff;
}

.header-cont {
    position: absolute;
    bottom: 30px;
}

.side-img {
    position: absolute;
    right: 0;
    bottom: 0;
}

.side-img1 {
    position: absolute;
    right: 20px;
    bottom: 0;
}

.side-img1 img {
    height: 60vh !important;
}

.side-img2 {
    position: absolute;
    right: 20px;
    bottom: 0;
}

.side-img2 img {
    height: 65vh !important;
}

.side-img img {
    height: 60vh !important;
}

.home-head {
    margin-top: 50px;
}


.head-con {
    margin-top: 50px;
    font-size: 40px;
    font-family: 'GorditaBold';
    line-height: 1.3;
    color: #fff;
}

.head-ques {
    color: #fff;
    font-family: 'GorditaRegular';
    font-size: 22px;
}

.home-book-btn {
    color: #1585FF;
    padding: 11px 20px 7px 20px !important;
    border-radius: 0.75em;
    font-family: 'GorditaBold';
    border: none;
}

.user-img {
    margin-top: 5px;
}

.logo-img {}

.navbar {
    z-index: 1 !important;
    padding: 13px 0 !important;
}

/* .avatar img {
    width: fit-content !important;
} */

.header-pic {
    width: fit-content !important;
}

.nav-cont {
    background-color: #fff;
    border-radius: 0.25em;
    padding: 13px;
    margin: 20px !important;
    border: 1px solid #bcddff;
}

.navbar-nav {
    margin-top: 0;
    margin-left: auto;
}

.list-item {
    font-family: 'GorditaRegular';
    color: #1585FF !important;
    text-decoration: none !important;
    font-size: 17px !important;
}

.navbar-nav .nav-item {
    margin-right: 20px;
}

.book-btn {
    font-family: 'GorditaRegular';
    background-color: #1585FF !important;
    letter-spacing: 1px;
    border-radius: 0.5em;
    color: #fff !important;
    padding: 8px 10px 5px 10px !important;
    font-size: 12px !important;
    margin-top: 4px;
    text-transform: uppercase;
}

.header-hr {
    margin: 0 !important;
    border: 1px solid #1585FF !important;
}

.side-image {
    display: none;
}

.help-section {
    position: absolute;
    bottom: 100px;
}

.help-head {
    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 40px;
    line-height: 1.1;
}

.help-desc {
    color: #1585FF;
    font-family: 'GorditaLight';
    font-size: 17px;
}

.book-doc-btn {
    color: #fff;
    background-color: #1585FF;
    font-family: 'GorditaBold';
    padding: 11px 20px 7px 20px !important;
    font-size: 16px;
    border: none;
    border-radius: 0.5em;
}

.legal-bg {
    position: relative;
    background-image: url('../images/Legal_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 90vh;
}

.news-bg {
    position: relative;
    background-image: url('../images/Newsroom/Newsroom_banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    height: 90vh;
}

.profile {

    color: #1585FF;
    font-family: 'GorditaBold';
    font-size: 52px;
    line-height: 1.1;
}

.header-pro {
    display: none !important;
}

/* .profilesection{
    padding-left: 120px;
} */



@media (max-width: 1200px) {
    .head-con {
        font-size: 22px;
        margin-top: 0;
    }

    .head-ques {
        font-size: 18px;
    }
}

@media (max-width: 1000px) {
    .navbar-nav .nav-item {
        margin-right: 3px !important;
    }

    .head-con {
        margin-top: 0;
    }
}

@media (max-width: 767px) {
    .avatar {
        display: none !important;
    }

    .header-pro {
        display: block !important;
    }

    .home-img {
        height: auto;
    }

    .side-image {
        position: relative;
        display: block;
        width: 100%;
    }

    .side-img {
        display: none;
    }

    .side-img1 {
        display: none;
    }

    .side-img2 {
        display: none;
    }

    .user-img {
        margin-top: 10px;
    }

    .book-btn {
        background-color: white !important;
        color: #1585FF !important;
        padding: 0 !important;
        padding-bottom: 5px !important;
        font-size: 17px !important;
        text-transform: none;
        letter-spacing: normal;
        margin-top: 7px;
    }
}